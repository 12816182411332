import React, { useState } from "react";
import axios from "../axios-orders";
import { toast } from "react-toastify";

const initContactInfo = {
  firstName: "",
  email: "",
  message: "",
};
const Contact = (props) => {
  const [contactInfo, setContactInfo] = useState(initContactInfo);

  const inputChangeHandler = (e) => {
    const updatedContactInfo = { ...contactInfo };
    updatedContactInfo[e.target.name] = e.target.value;
    setContactInfo(updatedContactInfo);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    axios
      .post("/contactInfo.json", contactInfo)
      .then((res) => {
        toast.success("Thanks for cantact us. We will reach out to you soon.");
        setContactInfo(initContactInfo);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err.message);
      });
  };

  return (
    // Contact section start
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 m-auto">
            <div className="sec-heading">
              <h3 className="sec-title">Get In Touch</h3>
              <p>
                Sorry, the comment feature currently is not available,
                please send a message or make a call directly for inquiry.
                Inquiry phone number: 236 638 9259 (Duncan).
              </p>
              <p>
                We are opening from 9am - 11pm.

              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 col-md-12 m-auto">
            <div className="row">
              <div className="col-md-4">
                <address>
                  <span className="ti-map-alt"></span>
                  5833 York Road<br />
                  Duncan, Vancouver Island
                </address>
                {/* <address>
                  <span className="ti-email"></span>
                  <a href="mailto:huyi.mei70@gmail.com">huyi.mei70@gmail.com</a>
                  <a href="mailto:huyi.mei70@gmail.com">huyi.mei70@gmail.com</a>
                </address> */}
                <address>
                  <span className="ti-tablet"></span>
                  Telephone:<br/>
                  <a href="tel:+012508580465">
                    +1 236 638 9259
                  </a>
                </address>
              </div>
              <div className="col-md-7 offset-md-1">
                <form className="contact-form" onSubmit={onSubmitHandler}>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    onChange={inputChangeHandler}
                    value={contactInfo.firstName}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    onChange={inputChangeHandler}
                    value={contactInfo.email}
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Leave a comment"
                    onChange={inputChangeHandler}
                    value={contactInfo.message}
                    required
                  ></textarea>
                  <button type="submit" className="btn btn-round" disabled >
                    Submit now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // Contact section end
  );
};

export default Contact;
