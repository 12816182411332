import React from "react";
// import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    // Banner section start
    <section className="banner">
      <div className="spa-img">
        <img src={require("../assets/images/image4.jpeg")} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="banner-content">
              <span>SH Massage SPA</span>
              <h2>The place where makes you clam down.</h2>
              <p>
                Where whispers of tranquility and the art of touch intertwine,
                leaving you captivated by the secrets that lie within.
              </p>
              <a
              // href="#appointment_sec"
              href="/"
              className="btn"
              onClick={e =>e.preventDefault()}>
                Make a phone call for reservation:
                236 638 9259
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    // Banner section end
  );
};

export default Banner;
