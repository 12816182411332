// import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const breakPoints = {
  0: {
    items: 1,
    loop: false, // Disable loop on mobile screen sizes
  },
  576: {
    items: 1,
    loop: false, // Disable loop on mobile screen sizes
  },
  768: {
    items: 2,
    loop: true, // Enable loop on larger screen sizes
  },
  1039: {
    items: 3,
    loop: true, // Enable loop on larger screen sizes
  },
};

const Service = (props) => {
  return (
    // Service section start
    <section className="service">
      <OwlCarousel
        autoplay
        className="service-caro"
        dots={false}
        items={4}
        loop
        margin={5}
        nav={false}
        responsive={breakPoints}
      >
        <div className="single-service">
          <img src={require("../assets/images/service/1.jpg")} alt="" />
        </div>
        <div className="single-service">
          <img src={require("../assets/images/service/image1.jpeg")}  alt="" />
        </div>
        <div className="single-service">
          <img src={require("../assets/images/service/image2.jpeg")} width="208" height="249" alt="" />

        </div>
      </OwlCarousel>
    </section>
    // Service section end
  );
};

export default Service;
