import React from "react";
import { Link } from "react-router-dom";

const AboutSec = (props) => {
  return (
    // About section start
    <section className="about">
      <img
        src={require("../assets/images/china-rose.png")}
        alt=""
        className="flower-1"
      />
      <img
        src={require("../assets/images/jasmine.png")}
        alt=""
        className="flower-2"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-12 m-auto">
            <div className="sec-heading">
              <img src={require("../assets/images/icons/2.png")} alt="" />
              <span className="tagline">About the SH massage </span>
              <h3 className="sec-title">Come and you will be Inspired!</h3>
              <p>
                Discover our enchanting massage spa,
                a haven of tranquility nestled in the heart of our charming town.
                Immerse yourself in a world of soothing aromas, gentle melodies,
                and skilled therapists.
                Indulge in our luxurious treatments that will melt away your stress and rejuvenate your body and mind.
                Experience the perfect blend of relaxation and revitalization,
                as our dedicated team pampers you with exceptional care. Unwind,
                recharge, and embrace the serenity that awaits you at our esteemed massage spa.
                Your well-being deserves this extraordinary escape.
              </p>
            </div>
            <Link to="/about" className="btn btn-round">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>
    // About section end
  );
};

export default AboutSec;
